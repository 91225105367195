import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import { Router, withRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ContextProvider } from '@epam/uui-core';
import { Snackbar, Modals } from '@epam/uui-components';
import { ErrorHandler } from '@epam/promo';
import '@epam/uui-components/styles.css';
import '@epam/promo/styles.css';
import '@epam/uui/styles.css';
import { Api, AppContext, getApi, svc } from './services';
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';

const history = createBrowserHistory();

const ApolloApp = () => (
    <ApolloProvider client={ svc.apollo }>
        <App />
    </ApolloProvider>
);

const UuiEnhancedApp = withRouter(({ history }) => (
    <ContextProvider<Api, AppContext> 
        apiReloginPath={`/auth/relogin?redirect=${encodeURIComponent(window.location.pathname)}`}
        apiDefinition={getApi}
        onInitCompleted={ (context) => {
            Object.assign(svc, context);
            svc.apollo = new ApolloClient({
                link: new HttpLink({
                    fetch: (uri: RequestInfo, options: RequestInit | undefined) => context.api.query(JSON.parse(options?.body as string))
                        .then((data) => ({
                            ...JSON.parse(options?.body?.toString() ?? ''),
                            text: () => Promise.resolve(JSON.stringify(data)),
                            status: 200,
                        })),
                }),
                cache: new InMemoryCache(),
                defaultOptions: { query: { fetchPolicy: 'no-cache' } },
            })
        } }
        loadAppContext={ (api) => api.appContext()}
        history={ history }
    >
        <ErrorHandler>
            <ApolloApp />
            <Snackbar />
            <Modals />
        </ErrorHandler>
    </ContextProvider>
));

const RoutedApp = () => (
    <Router history={ history }>
        <UuiEnhancedApp />
    </Router>
);

ReactDOM.render(<RoutedApp />, document.getElementById('root'));
