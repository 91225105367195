export const sellersQuery = `
query($range: Range, $sorting: [SortOption], $filter: SellersFilter, $search: String) {
  sellers(range: $range, sorting: $sorting, filter: $filter, search: $search) {
    id
    name
    inns
  }
}`;

export const sellerQuery = `
query categories($filter: SellersFilter) {
  sellers(filter:$filter) {
    id
    name
    inns
    defaultCategoryId
    purchases {
      id
      dateTime
      items {
        id
        commodity {
          name
          category {
            icon
          }
        }
      }
    }
  } 
}
`;

export const sellerPickerQuery = `
query($range: Range, $sorting: [SortOption], $filter: SellersFilter, $search: String) {
  sellers(range: $range, sorting: $sorting, filter: $filter, search: $search) {
    id
    name
  }
}`;

export const updateSellerMutation = `mutation ($item: InputSeller) {
  sellerUpdate(item:$item) {
    id
    defaultCategoryId
    name
  }
}`;
