import React from 'react';
import { DataColumnProps } from '@epam/uui-core';
import { DataTableCell, Text } from '@epam/promo';

export const iconColumn = <T extends unknown>(iconGetter: ((item: T) => string | undefined)): DataColumnProps<T> => ({
  key: 'icon',
  caption: null,
  render: item => <Text color='gray60' font='sans'>{ iconGetter(item) }</Text>,
  isAlwaysVisible: true,
  width: 45,
  renderCell: (props) => <DataTableCell
      // { ...props.rowLens.prop('icon').toProps() }
      { ...props }
      padding='12'
      onValueChange={undefined}
    />,
});