import { useUuiContext } from "@epam/uui-core";
import { useMemo } from "react";
import { AppContext } from "../services";

export const useHasRole: (role: string) => boolean = (role) => {
  const svc = useUuiContext<unknown, AppContext>();

  return useMemo(
    () => !!svc.uuiApp.user?.roles?.includes(role),
    [role, svc]);
};