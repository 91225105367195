import React, { useCallback, useMemo, useState } from 'react';
import { Button, Checkbox, DataTable, Dropdown, DropdownContainer, ErrorNotification, FlexCell, FlexRow, LabeledInput, NumericInput, SearchInput, SuccessNotification, TabButton, Text, TextInput } from '@epam/promo';
import { DataColumnProps, DataRowProps, DataSourceState, Metadata, useArrayDataSource, useForm, useUuiContext } from '@epam/uui-core';
import { GraphQLCommodity, GraphQLPurchaseItem, GraphQLSeller } from 'common';

import { iconColumn } from '../components/iconColumn';
import { DefaultUrlOnlyState, MasterDetail } from '../components/MasterDetail';
import { UrlState } from '../hooks/useDataSourceState';
import { useUrlState } from '../hooks/useUrlState';
import { AdaptiveItemProps, AdaptivePanel } from '@epam/uui-components';
import { useHasRole } from '../hooks/useHasRole';
import { Api } from '../services';
import { CommodityPrice } from '../models';
import { commoditiesQuery, commodityQuery } from '../queries';
import { amountColumn } from '../components/amountColumn';
import { dateTimeColumn } from '../components/dateTimeColumn';
import { CategoryPicker } from '../components/CategoryPicker';

type UrlOnlyState = {
  tab?: string;
} & DefaultUrlOnlyState;

const extractUrlOnlyState: <TItem extends unknown>(urlState: UrlState<TItem, number, UrlOnlyState>) => UrlOnlyState = urlState => {
  const { tab } = urlState;

  return {
    tab,
  };
}

export const CommoditiesPage = () => {
  const [urlState, setUrlState] = useUrlState<UrlOnlyState>();

  const onSearchChange = useCallback((value: string) => {
    setUrlState({
      ...urlState,
      search: value,
    });
  }, [urlState, setUrlState]);

  const onCategoryChange = useCallback((value: number | undefined) => {
    setUrlState({
      ...urlState,
      filter: {
        categoryId: value,
      },
    });
  }, [urlState, setUrlState]);

  const filter = useMemo(() => ({
    categoryId: urlState.filter?.categoryId
  }), [urlState]);

  const renderSearch = (item: AdaptiveItemProps) => {
      return (
        <FlexCell width={200}>
          <SearchInput value={urlState.dateRange} onValueChange={onSearchChange} placeholder='Search' />
        </FlexCell>
      )
  };

  const renderCategory = (item: AdaptiveItemProps) => {
    return (
      <FlexCell width={200}>
        <CategoryPicker value={ filter.categoryId } onValueChange={onCategoryChange} allowNulls placeholder='Category' />
      </FlexCell>
    )
  };


  const items: AdaptiveItemProps<{data?: {caption: string}}>[] = [
    { id: '1', render: renderSearch, priority: 1 },
    { id: '2', render: renderCategory, priority: 1 },
    { id: '5', render: (item, hiddenItems) => <Dropdown
          renderTarget={ (props) => <Button caption='Hidden Filters...' { ...props } /> }
          renderBody={ () => <DropdownContainer>{ hiddenItems?.map(item => {
            if (item.id === '1') {
              return (
                <FlexRow padding='6' vPadding='12'>
                  {renderSearch(item)}
                </FlexRow>
              );
            } else {
              return (
                <FlexRow padding='6' vPadding='12'>
                  {renderCategory(item)}
                </FlexRow>
              );
            }
          }) }</DropdownContainer> }
      />,
      priority: 10, collapsedContainer: true,
    },
  ];  

  const search = useMemo(() => urlState.search, [urlState.search]);

  return <MasterDetail<GraphQLCommodity, UrlOnlyState>
    masterQuery={ commoditiesQuery }
    detailQuery = { commodityQuery }
    pathname='commodities'
    resultQueryProp='commodities'
    columns={ columns }
    Detail={ CommodityDetails }
    extractUrlOnlyState={extractUrlOnlyState}
    filter={filter}
    search={search}
  >
    <AdaptivePanel items={items} />
  </MasterDetail>;
};

const columns: DataColumnProps<GraphQLCommodity>[] = [
  iconColumn(commodity => commodity.category?.icon), {
    key: 'id',
    caption: 'Id',
    render: commodity => <Text color='gray80' font='sans-semibold'>{ commodity.id }</Text>,
    isSortable: true,
    width: 60,
  }, {
    key: 'name',
    caption: 'NAME',
    render: commodity => <Text color='gray80' font='sans-semibold'>{ commodity.name }</Text>,
    isSortable: true,
    grow: 1,
    width: 224,
  },
  amountColumn<GraphQLCommodity>({ key: 'purchaseCount', caption: 'Count', amountGetter: commodity => commodity.purchaseCount, isSortable: true }),
  amountColumn<GraphQLCommodity>({ key: 'purchaseSum', caption: 'Sum', amountGetter: commodity => commodity.purchaseSum, isSortable: true }), {
    key: 'categoryName',
    caption: 'CATEGORY',
    render: commodity => <Text>{ commodity.category?.name }</Text>,
    width: 144,
  }, {
    key: 'codes',
    caption: 'CODES',
    render: commodity => <Text>{ commodity.codes?.join(', ') }</Text>,
    width: 144,
  },
];

interface CommodityDetailsProps {
  item: GraphQLCommodity;
}

const CommodityDetails = ({ item }: CommodityDetailsProps) => {
  const [urlState, setUrlState] = useUrlState<UrlOnlyState>();
  const uui = useUuiContext<Api>();

  type UpdateCommodity = Pick<GraphQLCommodity, 'id' | 'categoryId'>;

  const getMetadata = (state: UpdateCommodity): Metadata<UpdateCommodity> => ({
    props: {
      categoryId: { isRequired: true, },
    },
  });

  const { lens, save } = useForm<UpdateCommodity>({
    value: {
      id: item.id,
      categoryId: item.categoryId,
    },
    onSave: async value => {
      const result = await uui.api.updateCommodity(value);

      if (typeof result === 'string') {
        uui.uuiErrors.reportError(new Error(`Error during commodity saving: ${result}`));
      }

      return {
        form: value,
      };
    },

    onSuccess: async result => {
        uui.uuiNotifications.show(props => {
          return (
            <SuccessNotification { ...props }>
              <Text>Commodity saved</Text>
            </SuccessNotification>
          );
        });
      },
    onError: error => uui.uuiNotifications.show(props => (
      <ErrorNotification { ...props }>
          <Text>Error on save</Text>
      </ErrorNotification>
    )),
    getMetadata,
    settingsKey: 'commodity',
  });  

  return (
      <>
        <FlexRow padding='24' vPadding='12'>
          <FlexCell grow={1}>
            <LabeledInput label='ID'>
              <NumericInput
                value={ item.id }
                onValueChange={ () => {} }
                isReadonly
              />
            </LabeledInput>
          </FlexCell>
        </FlexRow>
        <FlexRow padding='24' vPadding='12'>
          <FlexCell grow={1}>
            <LabeledInput label='Name'>
              <TextInput
                value={ item.name }
                onValueChange={ () => {} }
                isReadonly
              />
            </LabeledInput>
          </FlexCell>
        </FlexRow>
        <FlexRow padding='24' vPadding='12'>
          <FlexCell grow={1}>
            <LabeledInput label='Codes'>
              <TextInput
                value={ item.codes?.join(', ') }
                onValueChange={ () => {} }
                isReadonly
              />
            </LabeledInput>
          </FlexCell>
        </FlexRow>
        <FlexRow padding='24' vPadding='12'>
          <FlexCell grow={1}>
            <LabeledInput label='Category'>
              <CategoryPicker
                { ...lens.prop('categoryId').toProps() }
                onValueChange={ (value) => {
                  lens.prop('categoryId').set(value);
                  save();
                }}
                searchPosition='body'
              />
            </LabeledInput>
          </FlexCell>
        </FlexRow>
        <FlexRow padding='24' vPadding='12'>
          <TabButton
            caption='Purchases'
            onClick={() => setUrlState({
              ...urlState,
              tab: 'purchases'
            })}
            isLinkActive={!urlState.tab || urlState.tab === 'purchases'}
            size='36'
          />
          <TabButton
            caption='Sellers'
            onClick={() => setUrlState({
              ...urlState,
              tab: 'sellers'
            })}
            isLinkActive={urlState.tab === 'sellers'}
            size='36'
          />
          <TabButton
            caption='Prices'
            onClick={() => setUrlState({
              ...urlState,
              tab: 'prices'
            })}
            isLinkActive={urlState.tab === 'prices'}
            size='36'
          />
        </FlexRow>
        <FlexRow padding='24' vPadding='12'>
          <FlexCell grow={1}>
              {(!urlState.tab || urlState.tab === 'purchases') && <CommodityPurchaseItems icon={item.category?.icon} items={item.purchaseItems} />}
              {urlState.tab === 'sellers' && <CommoditySellers items={item.sellers} />}
              {urlState.tab === 'prices' && <CommodityPrices items={item.prices} />}
          </FlexCell>
        </FlexRow>
    </>
  );
}

type CommodityPurchaseItemsProps = {
  icon?: string,
  items?: GraphQLPurchaseItem[],
};

const useColumns: (changeItem: (item: GraphQLPurchaseItem, newItem: Partial<GraphQLPurchaseItem>) => void) => DataColumnProps<GraphQLPurchaseItem>[] = (changeItem) => {
  const hasAdminRole = useHasRole('Admin');
  return useMemo(() => [...hasAdminRole ? [{
      key: 'isAdminOnly',
      caption: 'Admin Only',
      render: (item: GraphQLPurchaseItem) => <Checkbox label='' mode='cell' value={item.isAdminOnly} onValueChange={ isAdminOnly => changeItem(item, { isAdminOnly }) } />,
      width: 50,
    }] : [],
    iconColumn(item => item.commodity.category?.icon), {
      key: 'name',
      caption: 'Name',
      render: item => <Text color='gray80'>{ item.commodity.name }</Text>,
      isSortable: true,
      width: 300,
    },
    dateTimeColumn<GraphQLPurchaseItem>({ key: 'date', caption: 'date', dateTimeGetter: item => item.parent.dateTime }),
    amountColumn<GraphQLPurchaseItem>({ key: 'price', caption: 'Price', amountGetter: item => item.price, isSortable: true }),
    amountColumn<GraphQLPurchaseItem>({ key: 'quantity', caption: 'Quantity', amountGetter: item => item.quantity }),
    amountColumn<GraphQLPurchaseItem>({ key: 'sum', caption: 'Sum', amountGetter: item => item.sum }), {
      key: 'code',
      caption: 'Code',
      render: item => <Text color='gray80'>{ item.commodity.codes?.join(', ') }</Text>,
      grow: 1,
      width: 100,
    },
  ], [hasAdminRole, changeItem]);
};


export const CommodityPurchaseItems = ( props: CommodityPurchaseItemsProps) => {
  const [value, onValueChange] = useState<DataSourceState<any, number>>({});
  const [items, setItems] = useState<GraphQLPurchaseItem[]>(props.items || []);
  const dataSource = useArrayDataSource<GraphQLPurchaseItem, number, unknown>({
    items,
  }, [items]);
  const uui = useUuiContext<Api, unknown>();

  const clickHandler = useCallback((rowProps: DataRowProps<GraphQLPurchaseItem, number>) => {
    uui.uuiRouter.redirect({
      pathname: `/purchases`,
      query: {
        selectedId: rowProps.value?.purchaseId,
      },
    });
  }, [uui]);

  const view = dataSource.useView(value, onValueChange, {
    getRowOptions: () => ({
        onClick: clickHandler,
    }),
  });
  
  const changeItem = useCallback((item: GraphQLPurchaseItem, newItem: Partial<GraphQLPurchaseItem>) => {
    const itemIndex = items.findIndex(pi => pi === item);

    uui.api.updatePurchaseItem({
      ...newItem,
      id: item.id,
    }).then(result => {
      if (typeof result !== 'string') {
        const newItems = [...items];
        newItems[itemIndex] = { ...item, ...result };
        setItems(newItems);
      }
    })
  }, [items, uui]);

  const columns = useColumns(changeItem);

  return (
    <DataTable
      { ...view.getListProps() }
      getRows={ view.getVisibleRows }
      value={ value }
      onValueChange={ onValueChange }
      columns={ columns }
      headerTextCase='upper'
    />
  )  
}

type CommoditySellersProps = {
  items?: GraphQLSeller[],
};

export const CommoditySellers = ( { items }: CommoditySellersProps) => {
  const [value, onValueChange] = useState<DataSourceState<any, number>>({});
  const dataSource = useArrayDataSource<GraphQLSeller, number, unknown>({
    items: items || [],
  }, [items]);

  const view = dataSource.useView(value, onValueChange, {});
  
  const columns: DataColumnProps<GraphQLSeller>[] = useMemo(() => [
    {
      key: 'name',
      caption: 'Seller',
      render: item => <Text color='gray80'>{ item.name }</Text>,
      isSortable: true,
      width: 400,
    }
  ], []);

  return (
    <DataTable
      { ...view.getListProps() }
      getRows={ view.getVisibleRows }
      value={ value }
      onValueChange={ onValueChange }
      columns={ columns }
      headerTextCase='upper'
    />
  )  
}

type CommodityPricesProps = {
  items?: CommodityPrice[],
};

export const CommodityPrices = ( { items }: CommodityPricesProps) => {
  const [value, onValueChange] = useState<DataSourceState<any, number>>({});
  const dataSource = useArrayDataSource<CommodityPrice, number, unknown>({
    items: items || [],
  }, [items]);

  const view = dataSource.useView(value, onValueChange, {});
  
  const columns: DataColumnProps<CommodityPrice>[] = useMemo(() => [
    dateTimeColumn<CommodityPrice>({ key: 'startDate', caption: 'Start Date', dateTimeGetter: item => item.startDate,  isSortable: true }),
    dateTimeColumn<CommodityPrice>({ key: 'endDate', caption: 'End Date', dateTimeGetter: item => item.endDate, isSortable: true }),
    amountColumn<CommodityPrice>({ key: 'price', caption: 'Price', amountGetter: item => 0.01 * item.price, isSortable: true }),
  ], []);

  return (
    <DataTable
      { ...view.getListProps() }
      getRows={ view.getVisibleRows }
      value={ value }
      onValueChange={ onValueChange }
      columns={ columns }
      headerTextCase='upper'
    />
  )  
}