export const purchaseItemsQuery = `
query purchaseItems($filter:PurchaseItemsFilter, $range: Range, $sorting: [SortOption], $search: String) {
  purchaseItems(filter: $filter, range: $range, sorting: $sorting, search: $search) {
    id
    price
    quantity
    sum
    isAdminOnly
    commodity {
      id
      name
      codes
      categoryId
      category {
        id
        icon
      }
    }
    categoryId
    category {
      id
      icon
    }
    calculatedCategory {
      id
      icon
    }
    parent {
      id
      dateTime
    }
  }
}
`;

export const updatePurchaseItemMutation = `mutation ($item: InputPurchaseItem) {
  purchaseItemUpdate(item: $item) {
    id
    isAdminOnly
    categoryId
  }
}`;
