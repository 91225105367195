export const orphansQuery = `
query($filter: OrphansFilter) {
  orphans(filter: $filter) {
    id
    dateTime
    totalSum
    fiscalDocumentNumber
    fiscalDriveNumber
    fiscalSign
    seller {
      id
      name
    }
  }
}`;

export const processSlipMutation = `mutation ($item: InputProcessSlip) {
  processSlip(item: $item)
}`;
