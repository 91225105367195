import React, { useCallback, useMemo, useState } from 'react';
import { NumericInput, TextInput, FlexCell, FlexRow, DataTable, Text, LabeledInput, Checkbox, Button, Dropdown, DropdownContainer, DatePicker, SuccessNotification, ErrorNotification, Blocker, Panel, DropdownMenuButton, ModalBlocker, ModalWindow, ModalHeader, ModalFooter, useForm, DropSpot, FileCard, LinkButton } from '@epam/promo';
import { AdaptiveItemProps, AdaptivePanel, FlexSpacer } from '@epam/uui-components';
import { DataColumnProps, DataSourceState, IEditable, IModal, Metadata, useArrayDataSource, useUuiContext } from '@epam/uui-core';
import { FileCardItem } from '@epam/uui';
import { Route, Switch } from 'react-router';
import { GraphQLPurchase, GraphQLPurchaseAttachment, GraphQLPurchaseItem } from 'common';
import { ReactComponent as MenuIcon } from '@epam/assets/icons/common/navigation-more_vert-12.svg';

import { amountColumn } from '../components/amountColumn';
import { DateTime } from '../components/DateTime';
import { dateTimeColumn } from '../components/dateTimeColumn';
import { iconColumn } from '../components/iconColumn';
import { DefaultUrlOnlyState, MasterDetail } from '../components/MasterDetail';
import { UrlState } from '../hooks/useDataSourceState';
import { useHasRole } from '../hooks/useHasRole';
import { useUrlState } from '../hooks/useUrlState';
import { PurchaseFilter } from '../models';
import { purchaseQuery, purchasesQuery } from '../queries';
import { Api, AppContext } from '../services';
import { NewPurchasePage } from './NewPurchasePage';
import { SellerPicker } from '../components/SellerPicker';
import { CategoryPicker } from '../components/CategoryPicker';
import { FileIcon } from '../components/FileIcon';
import { flatten } from 'ramda';

const columns: DataColumnProps<GraphQLPurchase>[] = [
  {
    key: 'id',
    caption: 'ID',
    render: purchase => <Text color='gray60'>{ purchase.id }</Text>,
    isSortable: true,
    isAlwaysVisible: true,
    width: 80,
  },
  dateTimeColumn<GraphQLPurchase>({ key: 'dateTime', caption: 'Date Time', dateTimeGetter: purchase => purchase.dateTime, isSortable: true }),
  amountColumn({ key: 'sum', caption: 'Sum', amountGetter: purchase => purchase.sum, isSortable: true }), {
    key: 'seller',
    caption: 'Seller',
    render: purchase => <Text color='gray80' font='sans-semibold'>{ purchase.seller.name }</Text>,
    grow: 1,
    width: 200,
  },
];

type UrlOnlyState = {
  filter?: PurchaseFilter;
} & DefaultUrlOnlyState;

const extractUrlOnlyState: <TItem extends unknown>(urlState: UrlState<TItem, number, UrlOnlyState>) => UrlOnlyState = urlState => {
  const { filter } = urlState;

  return {
    filter,
  };
}

export const PurchasesPage = () => {
  const svc = useUuiContext();

  const [urlState, setUrlState] = useUrlState<UrlOnlyState>();

  const onDateFromChange = useCallback((value: string) => {
    setUrlState({
      ...urlState,
      filter: {
        ...urlState.filter,
        dateFrom: value,
      },
    });
  }, [urlState, setUrlState]);

  const onDateToChange = useCallback((value: string) => {
    setUrlState({
      ...urlState,
      filter: {
        ...urlState.filter,
        dateTo: value,
      },
    });
  }, [urlState, setUrlState]);

  const onSellerChange = useCallback((value: number) => {
    setUrlState({
      ...urlState,
      filter: {
        ...urlState.filter,
        sellerId: value,
      },
    });
  }, [urlState, setUrlState]);

  const filter = useMemo(() => ({
    dateFrom: urlState.filter?.dateFrom,
    dateTo: urlState.filter?.dateTo,
    sellerId: urlState.filter?.sellerId,
  }), [urlState]);

  const renderNewButton = (item: AdaptiveItemProps) => {
    return (
      <FlexCell width={200}>
        <Button caption='New Purchase...' onClick={() => svc.uuiRouter.redirect({ pathname: '/purchases/new' })} />
      </FlexCell>
    )
  };

  const renderDateFrom = (item: AdaptiveItemProps) => {
    return (
      <FlexCell width={200}>
        <DatePicker value={filter.dateFrom} onValueChange={onDateFromChange} />
      </FlexCell>
    )
  };

  const renderDateTo = (item: AdaptiveItemProps) => {
    return (
      <FlexCell width={200}>
        <DatePicker value={filter.dateTo} onValueChange={onDateToChange} />
      </FlexCell>
    )
  };

  const renderSeller = (item: AdaptiveItemProps) => {
    return (
      <FlexCell width={200}>
        <SellerPicker value={filter.sellerId} onValueChange={onSellerChange} />
      </FlexCell>
    )
  };

  const adaptiveItems: AdaptiveItemProps<{data?: {caption: string}}>[] = [
    { id: '1', render: renderNewButton, priority: 1 },
    { id: '2', render: renderDateFrom, priority: 2 },
    { id: '3', render: renderDateTo, priority: 2 },
    { id: '4', render: renderSeller, priority: 2 },
    { id: '5', render: (item, hiddenItems) => <Dropdown
          renderTarget={ (props) => <Button caption='Hidden Filters...' { ...props } /> }
          renderBody={ () => <DropdownContainer>{ hiddenItems?.map(item => {
            if (item.id === '1') {
              return (
                <FlexRow padding='6' vPadding='12'>
                  {renderNewButton(item)}
                </FlexRow>
              );
            } else if (item.id === '2') {
              return (
                <FlexRow padding='6' vPadding='12'>
                  {renderDateFrom(item)}
                </FlexRow>
              );
            } else if (item.id === '3') {
              return (
                <FlexRow padding='6' vPadding='12'>
                  {renderDateTo(item)}
                </FlexRow>
              );
            } else if (item.id === '4') {
              return (
                <FlexRow padding='6' vPadding='12'>
                  {renderSeller(item)}
                </FlexRow>
              );
            }

            return undefined;
          })
        }</DropdownContainer> }
      />,
      priority: 10, collapsedContainer: true,
    },
  ];  

  return (
    <Switch>
      <Route path='/purchases/new' exact component={NewPurchasePage} />
      <Route path='*' render={() => <MasterDetail<GraphQLPurchase, UrlOnlyState>
        masterQuery={ purchasesQuery }
        detailQuery = { purchaseQuery }
        pathname='purchases'
        resultQueryProp='purchases'
        columns={ columns }
        Detail={ PurchaseDetails }
        extractUrlOnlyState={extractUrlOnlyState}
        filter={filter}
      >
        <AdaptivePanel items={ adaptiveItems } />
      </MasterDetail> } />
  </Switch>);
};

interface PurchaseDetailsProps {
  item: GraphQLPurchase;
}

interface DropdownBodyProps {
  item: GraphQLPurchaseItem;
}

const DropdownBody = ({ item }: DropdownBodyProps) => {
  const svc = useUuiContext();
  return (
    <Panel background="white" shadow={true}>
      <DropdownMenuButton caption="Change Category..." onClick={() => {
        svc.uuiModals.show<unknown>((props: IModal<unknown>) => <ChangeCategoryModal {...props} item={item} />)
          .then(async () => {
            svc.uuiNotifications.show((props) => (
              <SuccessNotification { ...props }>
                <FlexRow alignItems='center'>
                  <Text>Changed</Text>
                </FlexRow>
              </SuccessNotification>
            )).catch(() => null);
          });
      }} />
    </Panel>
  );
};

const ChangeCategoryModal = (props: IModal<unknown> & DropdownBodyProps) => {
  const svc = useUuiContext<Api, AppContext>();

  type UpdatePurchaseItem = Pick<GraphQLPurchaseItem, 'id' | 'categoryId'>;
  const getMetadata = (state: UpdatePurchaseItem): Metadata<UpdatePurchaseItem> => ({
    props: {
      categoryId: { isRequired: false, },
    },
  });

  const { lens, save } = useForm<UpdatePurchaseItem>({
    value: {
      id: props.item.id,
      categoryId: props.item.categoryId,
    },
    onSave: async value => {
      const result = await svc.api.updatePurchaseItem(value);

      if (typeof result === 'string') {
        svc.uuiErrors.reportError(new Error(`Error during purchase saving: ${result}`));
      }

      return {
        form: value,
      };
    },
    onSuccess: async result => {
        svc.uuiRouter.redirect({ pathname: '/purchases'});
        svc.uuiNotifications.show(props => {
          return (
            <SuccessNotification { ...props }>
              <Text>Purchase item saved</Text>
            </SuccessNotification>
          );
        });
        props.success('Success action');
      },
    onError: error => svc.uuiNotifications.show(props => (
      <ErrorNotification { ...props }>
          <Text>Error on save</Text>
      </ErrorNotification>
    )),
    getMetadata,
    settingsKey: 'edit-purchase-item-category',
});  

  return (
    <ModalBlocker { ...props }>
      <ModalWindow>
          <Panel background="white">
            <ModalHeader title="Change Category" onClose={ () => props.abort() } />
            <FlexRow padding='24' vPadding='12'>
              <FlexCell grow={1}>
                <LabeledInput label='New Category'>
                <CategoryPicker
                  { ...lens.prop('categoryId').toProps() as IEditable<number | undefined> }
                  allowNulls
                />
                </LabeledInput>
              </FlexCell>
            </FlexRow>
            <FlexRow padding='24' vPadding='12'>
              <FlexCell grow={1}>
                <LabeledInput label='Default Category'>
                <CategoryPicker
                  value={props.item.commodity?.categoryId}
                  onValueChange={() => {}}
                  isReadonly
                />
                </LabeledInput>
              </FlexCell>
            </FlexRow>
            
            <ModalFooter>
              <FlexSpacer />
              <Button color='gray50' fill='white' caption='Cancel' onClick={ () => props.abort() } />
              <Button color='green' caption='Ok' onClick={ save } />
            </ModalFooter>
          </Panel>
      </ModalWindow>
    </ModalBlocker>
  );
};

const useColumns: (changeItem: (item: GraphQLPurchaseItem, newItem: Partial<GraphQLPurchaseItem>) => void) => DataColumnProps<GraphQLPurchaseItem>[] = (changeItem) => {
  const hasAdminRole = useHasRole('Admin');
  return useMemo(() => [...hasAdminRole ? [{
      key: 'isAdminOnly',
      caption: 'Admin Only',
      render: (item: GraphQLPurchaseItem) => <Checkbox label='' mode='cell' value={item.isAdminOnly} onValueChange={ isAdminOnly => changeItem(item, { isAdminOnly }) } />,
      width: 50,
    }] : [],
    iconColumn(item => item.category?.icon ?? item.commodity.category?.icon), {
      key: 'id',
      caption: 'Id',
      render: item => <Text color='gray80'>{ item.id }</Text>,
      isSortable: true,
      width: 70,
    }, {
      key: 'menu',
      isAlwaysVisible: true,
      render: item => (<Dropdown
        renderBody={() => <DropdownBody item={item} />}
        renderTarget={props => <Button {...props} fill="white" icon={MenuIcon} size="36" isDropdown={false} />}
        placement="bottom-end"
      />),
      width: 50,
    }, {
      key: 'name',
      caption: 'Name',
      render: item => <Text color='gray80'>{ item.commodity.name }</Text>,
      isSortable: true,
      width: 300,
    }, {
      key: 'price',
      caption: 'Price',
      render: item => <Text color='gray80'>{ item.price }</Text>,
      isSortable: true,
      width: 100,
    }, {
      key: 'quantity',
      caption: 'Quantity',
      render: item => <Text color='gray80'>{ item.quantity }</Text>,
      grow: 1,
      width: 100,
    }, {
      key: 'sum',
      caption: 'Sum',
      render: item => <Text color='gray80'>{ item.sum }</Text>,
      grow: 1,
      width: 100,
    }, {
      key: 'code',
      caption: 'Code',
      render: item => <Text color='gray80'>{ item.commodity.codes?.join(', ') }</Text>,
      grow: 1,
      width: 100,
    },
  ], [hasAdminRole, changeItem]);
};

const PurchaseDetails = ({ item }: PurchaseDetailsProps) => {
  const [value, onValueChange] = useState<DataSourceState<any, number>>({});
  const [items, setItems] = useState<GraphQLPurchaseItem[]>(item.items);
  const svc = useUuiContext<Api, AppContext>();

  const dataSource = useArrayDataSource<GraphQLPurchaseItem, number, unknown>({
      items,
  }, [items]);

  const [slip, onSlipChange] = useState<DataSourceState<unknown, string>>({});
  const slipProps = objectToPropsArray(item.slip);

  const slipDataSource = useArrayDataSource<Result, string, unknown>({
    items: slipProps,
    getId: item => item.id,
    getParentId: item => item.parent, 
  }, [item.slip]);

  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const deletePurchase = useCallback(() => {
    setIsProcessing(true);

    svc.api.deletePurchase(item.id)
      .then(async response => {
        setIsProcessing(false);

        if (response?.errors) {
          throw new Error(response.errors.map((i: any) => i.message).join(', '));
        }

        await svc.uuiNotifications.show(props => (
          <SuccessNotification { ...props }>
            <Text>{ response?.data?.deletePurchase }</Text>
          </SuccessNotification>
        ), {
          duration: 5,
        });
      })
      .catch(async response => {
        setIsProcessing(false);
        await svc.uuiNotifications.show(props => (
          <ErrorNotification { ...props }>
            <Text>Something went wrong: { response?.toString() }</Text>
          </ErrorNotification>
        ), {
          duration: 5,
        });
      });
  }, [item, svc, setIsProcessing]);

  const changeItem = useCallback((item: GraphQLPurchaseItem, newItem: Partial<GraphQLPurchaseItem>) => {
    const itemIndex = items.findIndex(pi => pi === item);

    svc.api.updatePurchaseItem({
      ...newItem,
      id: item.id,
    }).then(result => {
      if (typeof result !== 'string') {
        const newItems = [...items];
        newItems[itemIndex] = { ...item, ...result };
        setItems(newItems);
      }
    })
  }, [items, svc]);

  const view = dataSource.useView(value, onValueChange, {});
  const slipView = slipDataSource.useView(slip, onSlipChange, {});

  const columns = useColumns(changeItem);

  const slipColumns: DataColumnProps<Result, string>[] = [{
    key: 'prop',
    caption: 'Property',
    render: (item: Result) => <Text color='gray80'>{ item.prop }</Text>,
    width: 300,

  }, {
    key: 'value',
    caption: 'Value',
    isAlwaysVisible: true,
    render: (item: Result) => <Text color='gray80'>{ item.value }</Text>,
    width: 300,
    grow: 1,
  }];

  const [attachments, setAttachments] = useState<FileCardItem[]>([]);
  const [tempIdCount, setTempIdCount] = useState<number>(0);

  const trackProgress = (progress: number, id: number) => {
    setAttachments(attachments => attachments.map(item => (item.id === id ? { ...item, progress } : item)));
  };

  const updateFile = (file: FileCardItem, id: number) => {
    setAttachments(attachments => attachments.map(item => (item.id === id ? file : item)));
  };

  const deleteFile = (file: FileCardItem) => {
    setAttachments(attachments => attachments.filter(item => item.id !== file.id));
  };

  const uploadFile = (files: File[]) => {
    const tempId = tempIdCount;
    setTempIdCount(tempId + 1);
    const newAttachments = [...attachments];

    for (const file of files) {
      const newFile: FileCardItem = { id: tempId, name: file.name, progress: 0, size: file.size };
      newAttachments.push(newFile);

      svc.uuiApi
        .uploadFile('/upload', file, {
          onProgress: progress => trackProgress(progress, tempId),
          getXHR: xhr => {
            newFile.abortXHR = () => xhr.abort();
          },
        })
        .then(res => updateFile({ ...res, progress: 100 }, tempId))
        .catch(err => updateFile({ ...newFile, progress: 100, error: err.error }, tempId));
    }

    setAttachments(newAttachments);
  };
  
  type UpdatePurchase = Pick<GraphQLPurchase, 'id' | 'attachments'>;

  const getMetadata = (state: UpdatePurchase): Metadata<UpdatePurchase> => ({
    props: {
    },
  });

  const { save } = useForm<UpdatePurchase>({
    value: {
      id: item.id,
      attachments: attachments.map(attachment => ({
        uploadId: attachment.id!,
        purchaseId: item.id,
      } as GraphQLPurchaseAttachment)), // TODO fix it
    },
    onSave: async value => {
      await svc.api.updatePurchase(value);

      return {
        form: value,
      };
    },

    onSuccess: async result => {
        svc.uuiNotifications.show(props => {
          return (
            <SuccessNotification { ...props }>
              <Text>Purchase saved</Text>
            </SuccessNotification>
          );
        });
      },
    onError: error => svc.uuiNotifications.show(props => (
      <ErrorNotification { ...props }>
          <Text>Error on save</Text>
      </ErrorNotification>
    )),
    getMetadata,
    settingsKey: 'purchase-update',
  });  

  const hasAdminRole = useHasRole('Admin');

  return (
    <>
      <Blocker isEnabled={isProcessing} />
      <FlexRow padding='24' vPadding='12'>
        <FlexCell grow={1}>
          <LabeledInput label='ID'>
            <NumericInput
              value={ item.id ?? null }
              onValueChange={ () => {} }
              isReadonly
            />
          </LabeledInput>
        </FlexCell>
      </FlexRow>
      <FlexRow padding='24' vPadding='12'>
        <FlexCell grow={1}>
          <LabeledInput label='Date Time'>
            <DateTime
              value={ item.dateTime }
            />
          </LabeledInput>
        </FlexCell>
      </FlexRow>
      <FlexRow padding='24' vPadding='12'>
        <FlexCell grow={1}>
          <LabeledInput label='Sum'>
            <NumericInput
              value={ item.sum ?? null }
              onValueChange={ () => {} }
              isReadonly
              formatOptions={{ maximumFractionDigits: 2, minimumFractionDigits: 2 }}
            />
          </LabeledInput>
        </FlexCell>
      </FlexRow>
      <FlexRow padding='24' vPadding='12'>
        <FlexCell grow={1}>
          <LabeledInput label='Seller'>
            <TextInput
              value={ item.seller.name }
              onValueChange={ () => {} }
              isReadonly
            />
          </LabeledInput>
        </FlexCell>
      </FlexRow>
      <FlexRow padding='24' vPadding='12'>
        <FlexCell grow={1}>
          <DataTable
            { ...view.getListProps() }
            getRows={ view.getVisibleRows }
            value={ value }
            onValueChange={ onValueChange }
            columns={ columns }
            headerTextCase='upper'
            />
          </FlexCell>
      </FlexRow>
      {
        item.attachments &&
        item.attachments.map((file) => {
          if (isImage(file.upload?.originalName)) {
            return <img src={`/download/${file.upload?.googleFileId}`} alt={ file.upload?.originalName }/>
          }

          return (
            <FlexRow padding='24' vPadding='12'>
              <FlexCell width={24}>
                <FileIcon fileName={file.upload?.originalName} />
              </FlexCell>
              <FlexCell grow={1}>
                <LinkButton caption={file.upload?.originalName} target='_blank' href={`/download/${file.upload?.googleFileId}`} />
              </FlexCell>
            </FlexRow>
          );
        })
      }

      <FlexRow padding='24' vPadding='12'>
        <FlexCell grow={1}>
          <DropSpot onUploadFiles={uploadFile} />
        </FlexCell>
      </FlexRow>
      {
        attachments.map((file, index) => (
          <FlexRow padding='24' vPadding='12'>
            <FlexCell grow={1}>
              <FileCard key={index} file={file} onClick={() => deleteFile(file)} />
            </FlexCell>
          </FlexRow>
        ))
      }
      {hasAdminRole && <FlexRow padding='24' vPadding='12'>
        <FlexCell grow={1}>
          <DataTable
            { ...slipView.getListProps() }
            getRows={ slipView.getVisibleRows }
            value={ slip }
            onValueChange={ onSlipChange }
            columns={ slipColumns }
            headerTextCase='upper'
            />
          </FlexCell>
      </FlexRow>}
      <FlexRow padding='24' vPadding='12'>
        <FlexSpacer />
        <Button color='green' caption='Update' isDisabled={!attachments.length} onClick={ save } />
        <Button color='red' caption='Delete' onClick={ deletePurchase } />
        <FlexSpacer />
      </FlexRow>
    </>
  );
}

const isImage = (fileName?: string) => {
  const extension = fileName?.split('.').pop();

  return ['gif', 'jpg', 'jpeg', 'svg', 'png', 'webp'].includes(extension || '');
}

type Result = {
  id: string,
  prop: string,
  value: string,
  parent?: string,
}

const getId = (prop: string, parent: string | undefined) => parent ? `${parent}.${prop}` : prop;

const objectToPropsArray = (slip: unknown, prop: string | undefined = undefined, parent: string | undefined = undefined): Result[] => {
  if (!prop) {
    return [{
      id: 'slip',
      prop: 'slip',
      value: '',
    }, ...objectToPropsArray(slip, 'slip', 'slip')];
  }

  if (Array.isArray(slip)) {
    return [{
      id: getId(prop, parent),
      prop,
      value: '',
      parent,
    },...flatten(slip.map((item, index) => [{
        id: `${getId(prop, parent)}.${index}`,
        prop: `${index}`,
        value: '',
        parent: getId(prop, parent),
      }, ...objectToPropsArray(item, `${index}`, `${getId(prop, parent)}.${index}`)],
    ))];
  }

  if (slip instanceof Object) {
    const entries = Object.entries(slip);

    return flatten(entries.map(([propName, value]) =>
      objectToPropsArray(value, propName, parent)
    ));
  }

  if (typeof slip === 'string') {
    return [{
      id: getId(prop, parent),
      prop,
      value: slip,
      parent,
    }]
  }
  
  if (typeof slip === 'number') {
    return [{
      id: getId(prop, parent),
      prop,
      value: slip.toString(),
      parent,
    }]
  }
  
  return [];
}