import React from 'react';
import { useLocation } from 'react-router-dom';
import { urlParser, useUuiContext } from '@epam/uui-core';

export function useUrlState<UrlState>() {
    const { search, pathname } = useLocation();
    const svc = useUuiContext();

    const params = React.useMemo(() => urlParser.parse(search), [search]);

    const changeUrl = React.useCallback((urlState: UrlState) => {
        const query = {
            ...urlParser.parse(search),
            ...urlState,
        } as UrlState;

        const newSearch = urlParser.stringify(query);

        svc.uuiRouter.transfer({
            pathname,
            search: newSearch,
        });
    }, [search, pathname, svc]);

    return [params, changeUrl];
}
