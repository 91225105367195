import React from 'react';
import { getCookie, setCookie, LazyDataSourceApi, useLazyDataSource } from '@epam/uui-core';
import { DropdownMenuButton, PickerInput } from '@epam/promo';

type User = {
  id: number,
  name: string,
  avatar?: string,
};

export interface MakeMeItemProps {
    api: LazyDataSourceApi<User, number | string, any>;
}

const cookie = {
    userId: 'MakeMe',
};

export const MakeMeItem = (props: MakeMeItemProps) => {
  const dataSource = useLazyDataSource<User, number, undefined>({
    api: props.api,
    getId:  i => i.id,
  }, [props.api]);

  return (<PickerInput<User, number | string>
      valueType='id'
      selectionMode='single'
      editMode='modal'
      value={ getCookie(cookie.userId) }
      onValueChange={ (userId: string) => {
          setCookie(cookie.userId, userId, { path: '/' });
          window.location.reload();
      } }
      getName= { item => item.name }
      dataSource={ dataSource }
      renderToggler={ props => <DropdownMenuButton caption={ `Make me ...` } onClick={ props.onClick } />  }
  />);
}
