import React from "react";

import { IconContainer } from "@epam/promo";

import { ReactComponent as DocIcon } from '@epam/assets/icons/common/file-file_word-24.svg';
import { ReactComponent as ExelIcon } from '@epam/assets/icons/common/file-file_excel-24.svg';
import { ReactComponent as PdfIcon } from '@epam/assets/icons/common/file-file_pdf-24.svg';
import { ReactComponent as ImgIcon } from '@epam/assets/icons/common/file-file_image-24.svg';
import { ReactComponent as VideoIcon } from '@epam/assets/icons/common/file-file_video-24.svg';
import { ReactComponent as FileIconSvg } from '@epam/assets/icons/common/file-file-24.svg';

import css from './FileIcon.module.scss';

interface FileIconProps {
  fileName?: string;
}

export const FileIcon = ({ fileName }: FileIconProps) => {
  const extension = fileName?.split('.').pop();

  switch (extension) {
    case 'doc':
    case 'docx': return <IconContainer size={ 24 } icon={ DocIcon } cx={ css.doc } />;
    case 'xls':
    case 'xlsx': return <IconContainer size={ 24 } icon={ ExelIcon } cx={ css.xls } />;
    case 'pdf': return <IconContainer size={ 24 } icon={ PdfIcon } cx={ css.pdf } />;
    case 'gif':
    case 'jpg':
    case 'jpeg':
    case 'svg':
    case 'png':
    case 'webp': return <IconContainer size={ 24 } icon={ ImgIcon } cx={ css.img } />;
    case 'avi':
    case 'mov':
    case 'mp4':
    case 'wmw':
    case 'mkv': return <IconContainer size={ 24 } icon={ VideoIcon } cx={ css.movie } />;
    default: return <IconContainer size={ 24 } icon={ FileIconSvg } cx={ css.default } />;
  }
};