import React, { ComponentType, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { FlexCell, FlexRow, DataTable, Panel, Text, ScrollBars, Spinner, FlexSpacer, IconButton } from '@epam/promo';
import { cx, DataColumnProps, DataRowProps, LazyDataSourceApiRequest, SortingOption, useLazyDataSource, useUuiContext, UuiError } from '@epam/uui-core';
import { ReactComponent as CrossIcon } from "@epam/assets/icons/common/navigation-close-24.svg";
import { UrlState, useDataSourceState } from '../hooks/useDataSourceState';
import { Api, AppContext } from '../services';
import css from './MasterDetail.module.scss';

export interface MasterDetailProps<TItem, TUrlOnlyState, TFilter> {
  pathname: string;
  masterQuery: string;
  detailQuery: string;
  resultQueryProp: string;
  columns: DataColumnProps<TItem>[];
  Detail: ComponentType<ItemProps<TItem>>;
  extractUrlOnlyState: (urlState: UrlState<TFilter, number, TUrlOnlyState>) => TUrlOnlyState;
  filter?: TFilter;
  search?: string;
  args?: any;
};

export type DefaultUrlOnlyState = {
  sorting?: SortingOption[],
  selectedId?: number,
}

export const extractDefaultUrlOnlyState: <TFilter extends unknown>(urlState: UrlState<TFilter, number, DefaultUrlOnlyState>) => DefaultUrlOnlyState = urlState => {
  return {};
}

export const MasterDetail = <TItem, TUrlOnlyState = DefaultUrlOnlyState, TFilter = {}>(props: PropsWithChildren<MasterDetailProps<TItem, TUrlOnlyState, TFilter>>) => {
  const svc = useUuiContext<Api, AppContext>();
  const [value, onValueChange] = useDataSourceState<TFilter, number, TUrlOnlyState>(props.extractUrlOnlyState);
  
  const closeInfoPanel = useCallback(() => {
    onValueChange({ 
      ...value,
      selectedId: undefined,
    });
  }, [value, onValueChange]);

  const clickHandler = useCallback((rowProps: DataRowProps<TItem, number>) => {
    onValueChange({ 
      ...value,
      selectedId: rowProps.id,
    });
  }, [value, onValueChange]);

  const dataSource = useLazyDataSource<TItem, number, unknown>({
    api: async (request: LazyDataSourceApiRequest<TItem, number, unknown>) => {
      const filter = {
        ...(!!request.filter && request.filter),
        ...(!!props.filter && props.filter),
      };

      const response = await svc.api.query({
        query: props.masterQuery,
        variables: {
          ...request,
          search: props.search,
          filter,
          ...(!!props.args && props.args),
        }
      });

      return {
        items: response.data[props.resultQueryProp],
      };
    }
  }, [svc, props.masterQuery, props.resultQueryProp, props.filter, props.search, props.args]);
  
  const view = dataSource.useView(value, onValueChange, {
    getRowOptions: () => ({
        onClick: clickHandler,
    }),
  });

  return (
      <div className={ cx(css.container, css.table) }>
        {props.children}
        <DataTable
            { ...view.getListProps() }
            getRows={ view.getVisibleRows }
            value={ value }
            onValueChange={ onValueChange }
            columns={ props.columns }
            headerTextCase='upper'
        />
          {value.selectedId && <Detail<TItem, UrlState<TFilter, number, TUrlOnlyState>>
            Item={ props.Detail }
            query={ props.detailQuery }
            resultQueryProp={ props.resultQueryProp }
            id={ +value.selectedId }
            onClose={ closeInfoPanel }
            urlState={value}
            setUrlState={onValueChange}
          />}
      </div>
  );
}

interface DetailProps<TItem, TUrlState> {
  id: number;
  onClose(): void;
  query: string;
  resultQueryProp: string;
  Item: ComponentType<ItemProps<TItem>>;
  urlState: TUrlState;
  setUrlState(value: TUrlState): void;
}

type ItemProps<TItem> = {
  item: TItem;
}

const Detail = <TItem, TUrlState>({ id, query, onClose, resultQueryProp, Item }: DetailProps<TItem, TUrlState>) => {
  const [item, setItem] = useState<TItem | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isNotFound, setNotFound] = useState<boolean>(false);

  if (isNotFound || isNaN(id)) {
    throw new UuiError({
      status: 404,
    });
  }

  const svc = useUuiContext<Api, AppContext>();

  useEffect(() => {
    setIsLoading(true);
    svc.api.query({
      query,
      variables: {
        filter: {
          id,
        },
      }
    }).then(result => {
      if (result.data && result.data[resultQueryProp] && result.data[resultQueryProp][0]) {
        setItem(result.data[resultQueryProp][0]);
      } else {
        setItem(null);
        setNotFound(true);
      }
      setIsLoading(false);
    });
  }, [id, svc, query, resultQueryProp]);

  return (
    <div className={ cx(css.sidebar, 'show') }>
      { !isLoading && item &&
      <Panel shadow cx={ cx(css.container, css.details) } background='white'>
        <FlexRow borderBottom padding="24">
          <Text size="48" font="sans-semibold">Detailed Information</Text>
          <FlexSpacer />
          <FlexCell shrink={ 0 } width="auto"><IconButton icon={ CrossIcon } onClick={ onClose }/></FlexCell>
        </FlexRow>      { isLoading && <Spinner /> }
        <ScrollBars hasTopShadow hasBottomShadow >
          <Item item={ item } />
       </ScrollBars>
      </Panel> }
    </div>
  );
}