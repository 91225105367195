import { useCallback, useState } from "react";
import { DataSourceState } from "@epam/uui-core";
import { useUrlState } from "./useUrlState";

export type UrlState<TItem, TId, TAdditional> = DataSourceState<TItem, TId> & TAdditional;

export const useDataSourceState = <TItem, TId, TAdditional = {}>(extractUrlOnlyState: (urlState: UrlState<TItem, TId, TAdditional>) => TAdditional): [
  UrlState<TItem, TId, TAdditional>,
  (value: UrlState<TItem, TId, TAdditional>) => void
] => {
  const [urlState, setUrlState] = useUrlState<UrlState<TItem, TId, TAdditional>>();
  const [value, setValue] = useState<UrlState<TItem, TId, TAdditional>>(urlState);

  const onValueChange = useCallback((value: UrlState<TItem, TId, TAdditional>) => {
    const newValue = {
      ...value,
      ...extractUrlOnlyState(urlState),
    };

    setValue(newValue);

    const newUrlState = {
      ...extractUrlOnlyState(newValue),
      sorting: newValue.sorting,
      selectedId: newValue.selectedId,
    }

    setUrlState(newUrlState);
  }, [extractUrlOnlyState, urlState, setUrlState]);

  return [value, onValueChange];
}