import React from 'react';
import { DataColumnProps } from '@epam/uui-core';
import { DataTableCell } from '@epam/promo';
import { DateTime } from './DateTime';

type DateTimeColumnOptions<T> = {
  key: string,
  caption: string,
  dateTimeGetter: (item: T) => string | undefined,
  isSortable?: boolean,
};

export const dateTimeColumn = <T extends unknown>(options: DateTimeColumnOptions<T>): DataColumnProps<T> => ({
  key: options.key,
  caption: options.caption,
  isSortable: options.isSortable,
  render: item => {
    const value = options.dateTimeGetter(item);
    return !!value && <DateTime value={value} />;
  },
  width: 150,
  renderCell: (props) => <DataTableCell
      { ...props }
      padding='12'
      onValueChange={undefined}
    />,
});
