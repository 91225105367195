import React from 'react';
import { useUuiContext } from '@epam/uui-core';
import { Api } from '../services';
import { categoriesPickerQuery } from '../queries';
import { LookupPicker } from './LookupPicker';

type CategoryPickerProps = {
  allowNulls?: false,
  value: number,
  onValueChange: (value: number) => void,
  placeholder?: string,
  isReadonly?: boolean,
  searchPosition?: 'input' | 'body' | 'none',
} | {
  allowNulls: true,
  value: number | undefined,
  onValueChange: (value: number | undefined) => void,
  placeholder?: string,
  isReadonly?: boolean,
  searchPosition?: 'input' | 'body' | 'none',
}

export const CategoryPicker = (props: CategoryPickerProps) => {
    const svc = useUuiContext<Api>();

    return props.allowNulls 
      ? <LookupPicker
          { ...props }
          allowNulls= { props.allowNulls }
          placeholder={props.placeholder}
          query={categoriesPickerQuery}
          queryProp='categories'
          isReadonly={props.isReadonly}
          searchPosition={props.searchPosition}
        />
      : <LookupPicker
          { ...props }
          allowNulls= { props.allowNulls }
          defaultValue={svc.uuiApp.defaultCategoryId}
          placeholder={props.placeholder}
          query={categoriesPickerQuery}
          queryProp='categories'
          isReadonly={props.isReadonly}
          searchPosition={props.searchPosition}
        />;
}
