import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useUuiContext } from '@epam/uui-core';
import css from './App.module.scss';
import { AppHeader } from './AppHeader';
import { CategoriesPage } from './pages/CategoriesPage';
import { CommoditiesPage } from './pages/CommoditiesPage';
import { LoginPage } from './pages/LoginPage';
import { SellersPage } from './pages/SellersPage';
import { Api, AppContext } from './services';
import { PurchasesPage } from './pages/PurchasesPage';
import { OrphansPage } from './pages/OrphansPage';
import { PurchaseItemsPage } from './pages/PurchaseItemsPage';

export const App = () => {
  const svc = useUuiContext<Api, AppContext>();

  return (
    <div className={ css.container }>
      {svc.uuiApp.isAuthenticated && <Route component={ AppHeader } />}
        <Switch>
          <Route path="/sellers" exact component={ SellersPage } />
          <Route path="/commodities" component={ CommoditiesPage } />
          <Route path="/categories" component={ CategoriesPage } />
          <Route path="/purchases" component={ PurchasesPage } />
          <Route path="/spendings" component={ PurchaseItemsPage } />
          { svc.uuiApp.user?.isAdmin && <Route path="/orphans" component={ OrphansPage } /> }
          <Route path="/auth/login" exact component={ LoginPage } />

          {!svc.uuiApp.isAuthenticated && <Redirect to="/auth/login" />}
          { svc.uuiApp.isAuthenticated && <Redirect from="/" to="/sellers" /> }
        </Switch>
    </div>
  );
}
