export const commoditiesQuery = `
query commodities($range: Range, $sorting: [SortOption], $search: String, $filter: CommoditiesFilter) {
  commodities(range: $range, sorting: $sorting, search: $search, filter: $filter) {
    id
    name
    codes
    purchaseCount,
    purchaseSum,
    categoryId
    category {
      icon
      name
    }
  }
}
`;

export const commodityQuery = `
query commodities($filter: CommoditiesFilter) {
  commodities(filter: $filter) {
    id
    name
    codes
    categoryId
    category {
      id
      icon
      name
    }
    purchaseItems {
      id
      purchaseId
      quantity
      price
      sum
      isAdminOnly
      parent {
        dateTime
        seller {
          name
        }
      }
      commodity {
        id
        name
        codes
        category {
          icon
        }
      }
    }
    sellers {
      id
      name
    }
    prices {
      id
      startDate
      endDate
      price
    }
  }
}
`;

export const updateCommodityMutation = `mutation ($item:InputCommodity) {
  commodityUpdate(item:$item) {
    id
    categoryId
  }
}`;
