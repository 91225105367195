import React, { useState } from 'react';
import { Blocker, Button, ErrorNotification, FlexCell, FlexRow, LabeledInput, Panel, Text, TextInput, useForm } from '@epam/promo';
import { useUuiContext } from '@epam/uui-core';
import { FlexSpacer } from '@epam/uui-components';

import { Api, AppContext, UserInfo } from '../services';
import css from './LoginPage.module.scss';

export const LoginPage = () => {
  const svc = useUuiContext<Api, AppContext>();
  const [isLogingIn, setIsLogingIn] = useState<boolean>(false);
  const { lens, save } = useForm<UserInfo>({
      value: {},
      onSave: async person => {
        setIsLogingIn(true);
        try {
          const result = await svc.api.login(person);
          setIsLogingIn(false);
          if (result.redirect) {
            window.open(result.redirect);
          }

          return { form: person };
        } catch (e) {
          setIsLogingIn(false);
          svc.uuiNotifications.show(props =>
            <ErrorNotification { ...props } >
                <Text>Login Error!</Text>
            </ErrorNotification>, { duration: 5 });
        }
      },
      onSuccess: person => {
        if (window.opener) {
          // TODO Doesn't work; opener is empty after redirect
          // https://github.com/epam/UUI/issues/1039
          window.opener.postMessage("authSuccess", "*");
        } else {
          svc.uuiRouter.redirect(decodeURIComponent(svc.uuiRouter.getCurrentLink().query.redirect || '/'));
        }
      },
      getMetadata: () => ({
          props: {
            username: { isRequired: true },
          },
      }),
  });


  return (
    <form>
      <Panel margin='24' background='white' shadow cx={ css.container }>
        <FlexSpacer />
        <FlexRow padding='24' vPadding='12'>
          <FlexSpacer />
          <FlexCell width={400}>
            <Text>
              Log In
            </Text>
          </FlexCell>
          <FlexSpacer />
        </FlexRow>
        <FlexRow padding='24' vPadding='12'>
          <FlexSpacer />
          <FlexCell width={400}>
            <LabeledInput label='Telergam Name' { ...lens.prop('username').toProps() } >
              <TextInput placeholder='Telegram Name' { ...lens.prop('username').toProps() } name="username" autoComplete="username" />
            </LabeledInput>
          </FlexCell>
          <FlexSpacer />
      </FlexRow>
        <FlexRow padding='24' vPadding='12'>
          <FlexSpacer />
          <Button color='green' caption='Confirm' onClick={ save } />
          <input type='submit' hidden />
          <FlexSpacer />
        </FlexRow>
        <FlexSpacer />
      </Panel>
      { isLogingIn && <Blocker isEnabled={ isLogingIn } /> }
    </form>
  );
}
