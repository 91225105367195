import React, { useState, ReactNode} from "react";
import { Burger, BurgerButton, BurgerSearch, Button, DropdownMenuBody, DropdownMenuButton, DropdownMenuSplitter, ErrorNotification, MainMenu, MainMenuAvatar, MainMenuButton, ModalBlocker, ModalFooter, ModalHeader, ModalWindow, Panel, SuccessNotification, Text } from "@epam/promo";
import { AdaptiveItemProps, Dropdown, FlexCell, FlexRow, FlexSpacer } from "@epam/uui-components";
import { IModal, useUuiContext } from "@epam/uui-core";
import { Api, AppContext } from "./services";
import { MakeMeItem } from "./components/MakeMe";
import css from './AppHeader.module.scss';

const menutItems = [
    { id: 'Sellers', priority: 9, link: { pathname: '/sellers'}, caption: "Sellers" },
    { id: 'Commodities', priority: 9, link: { pathname: '/commodities'}, caption: "Commodities" },
    { id: 'Categories', priority: 9, link: { pathname: '/categories'},  caption: "Categories" },
    { id: 'Purchases', priority: 9, link: { pathname: '/purchases'},  caption: "Purchases" },
    { id: 'Spendings', priority: 9, link: { pathname: '/spendings'},  caption: "Spendings" },
    { id: 'Orphans', priority: 9, link: { pathname: '/orphans'},  caption: "Orphans", isAdminOnly: true, },
];

export const AppHeader = () => {
    const svc = useUuiContext<Api, AppContext>();

    const [burgerSearchQuery, setBurgerSearchQuery] = useState<string | undefined>('');

    const renderBurger = (hiddenItems: AdaptiveItemProps<{caption?: string, isAdminOnly?: boolean}>[] | undefined, close: () => void): ReactNode => (
        <>
            <BurgerSearch
                value={ burgerSearchQuery }
                onValueChange={ setBurgerSearchQuery }
                placeholder="Type to search"
                onCancel={ () => setBurgerSearchQuery('') }
            />
            { hiddenItems && hiddenItems.filter(i => !i.isAdminOnly || svc.uuiApp.user?.isAdmin).map(i => {
                const menuItem = menutItems.find(item => item.id === i.id);

                if (menuItem) {
                    return <BurgerButton caption={ i.caption } link={ menuItem.link } onClick={close}  />
                }

                return <BurgerButton caption={ i.caption } />
            })}
        </>
    );

    const renderLogoutConfirmation = (props: IModal<unknown>) => {
        return (
            <ModalBlocker { ...props }>
                <ModalWindow>
                    <Panel background="white">
                        <ModalHeader title="Do you want to log out?" onClose={ () => props.abort() } />
                        <ModalFooter>
                            <FlexSpacer />
                            <Button color='gray50' fill='white' caption='Cancel' onClick={ () => props.abort() } />
                            <Button color='green' caption='Ok' onClick={ () => props.success('Success action') } />
                        </ModalFooter>
                    </Panel>
                </ModalWindow>
            </ModalBlocker>
        );
    };

    const logout = () => {
        svc.uuiModals.show<unknown>(renderLogoutConfirmation)
            .then(async () => {
                try {
                    await svc.api.logout();

                    svc.uuiNotifications.show((props) => (
                        <SuccessNotification { ...props }>
                            <FlexRow alignItems='center'>
                                <Text>Logged out</Text>
                            </FlexRow>
                        </SuccessNotification>
                    )).catch(() => null);

                    window.location.reload();
                } catch (e) {
                    svc.uuiNotifications.show(props =>
                      <ErrorNotification { ...props } >
                          <Text>Logout Error!</Text>
                      </ErrorNotification>, { duration: 5 }
                    ).catch(() => null);
                  }

            });
    }

    const renderAdminPart = () => {
        const isAdmin = svc.uuiApp.user?.isAdmin || false;

        return isAdmin && (<>
            <DropdownMenuButton href="/playground" target="_blank" caption="Playground" />
            <MakeMeItem api={ svc.api.makeMeApi } />
            <DropdownMenuSplitter />
        </>);
    };

    const renderAvatar = () => {
        return (
            <Dropdown
                renderTarget={ props => (
                    <MainMenuAvatar
                        avatarUrl={ svc.uuiApp.user?.avatarUrl}
                        isDropdown
                        { ...props }
                    />
                ) }
                renderBody={ props => (
                    <DropdownMenuBody { ...props }>
                        {renderAdminPart()}
                        <DropdownMenuButton caption="Log out" onClick={logout}/>
                    </DropdownMenuBody>
                ) }
                placement="bottom-end"
            />
        );
    };

    const getMenuItems = (): AdaptiveItemProps<{caption?: string}>[] => {
        return [
            { id: 'burger', priority: 100, collapsedContainer: true, render: (item, hiddenItems) => <Burger
                    renderBurgerContent={ ({ onClose }) => renderBurger(hiddenItems, onClose) }

                />,
            },
            ...menutItems.map(item => ({
                id: item.id,
                priority: item.priority,
                render: () => <MainMenuButton link={item.link} caption={item.caption} />,
                caption: item.caption
            })),
            { id: 'flexSpacer', priority: 9, render: () => <FlexSpacer />},
            { id: 'avatar', priority: 100, render: renderAvatar },
        ];
    };

    return (
        <FlexCell grow={ 1 }>
            <MainMenu cx={css.appHeader} items={ getMenuItems() } />
        </FlexCell>
    );
}