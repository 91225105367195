import { Button, Checkbox, DatePicker, ErrorNotification, FlexCell, FlexRow, FlexSpacer, LabeledInput, NumericInput, Panel, ScrollBars, SuccessNotification, Text, TextInput } from '@epam/promo';
import { Metadata, useForm, useUuiContext } from '@epam/uui-core';
import React from 'react';
import { CategoryPicker } from '../components/CategoryPicker';
import { LookupPicker } from '../components/LookupPicker';
import { NewPurchase } from '../models';
import { sellersQuery } from '../queries';
import { Api, AppContext } from '../services';
import css from './NewPurchasePage.module.scss';

export const NewPurchasePage = () => {
  const svc = useUuiContext<Api, AppContext>();

  const getMetadata = (state: NewPurchase): Metadata<NewPurchase> => ({
    props: {
        dateTime: { isRequired: true, },
        sum: { isRequired: true, },
        quantity: { isRequired: true, },
        name: { isRequired: true, },
        categoryId: { isRequired: true, },
        newSellerName: {
          validators: [
            (value, parent) => !!parent.newSeller && !value ? ['This field is mandatory'] : [],
          ],
        },
        sellerId: {
          validators: [
            (value, parent) => !parent.newSeller && !value ? ['This field is mandatory'] : [],
          ],
        },
    },
  });

  const { lens, save } = useForm<NewPurchase>({
    value: {
      newSeller: false,
    } as NewPurchase,
    onSave: async value => {
      const result = await svc.api.newPurchase(value);

      if (result.errors) {
        svc.uuiErrors.reportError(new Error(`Error during purchase creation: ${result.errors}`));
      }

      return {
        form: value,
      };
    },
    onSuccess: async result => {
        svc.uuiRouter.redirect({ pathname: '/purchases'});
        svc.uuiNotifications.show(props => {
          return (
            <SuccessNotification { ...props }>
              <Text>Form saved</Text>
            </SuccessNotification>
          );
        });
      },
    onError: error => svc.uuiNotifications.show(props => (
      <ErrorNotification { ...props }>
          <Text>Error on save</Text>
      </ErrorNotification>
    )),
    getMetadata,
    settingsKey: 'new-purchase',
});  

  return (
    <div className={ css.container }>
      <Panel margin='24' background='white' shadow>
        <ScrollBars>
          <FlexSpacer />
          <FlexRow padding='24' vPadding='12'>
            <FlexCell grow={ 1 } width="auto">
              <Text size="48" font="sans-semibold">New Purchase</Text>
            </FlexCell>
          </FlexRow>
          <FlexSpacer />
          <FlexRow padding='24' vPadding='12'>
            <FlexCell grow={1} >
              <LabeledInput label='Purchase Date' { ...lens.prop('dateTime').toProps() } >
                  <DatePicker
                    { ...lens.prop('dateTime').toProps() }
                  />
              </LabeledInput>
            </FlexCell>
          </FlexRow>
          <FlexRow padding='24' vPadding='12'>
            <FlexCell grow={1} >
              <LabeledInput label='Seller' { ...(lens.prop('newSeller').get() ? lens.prop('newSellerName').toProps() : lens.prop('sellerId').toProps()) } >
                <Checkbox { ...lens.prop('newSeller').toProps() } label='Create new' />
                {lens.prop('newSeller').get()
                  ? <TextInput { ...lens.prop('newSellerName').toProps() } />
                  : <LookupPicker
                    { ...lens.prop('sellerId').toProps() }
                    query={sellersQuery}
                    queryProp='sellers'
                    allowNulls={false}
                    defaultValue={0}
                  />
                }
              </LabeledInput>
            </FlexCell>
          </FlexRow>
          <FlexRow padding='24' vPadding='12'>
            <FlexCell grow={1} >
              <LabeledInput label='Purchase Category' { ...lens.prop('categoryId').toProps() } >
                  <CategoryPicker
                    allowNulls={false}
                    { ...lens.prop('categoryId').toProps() }
                  />
              </LabeledInput>
            </FlexCell>
          </FlexRow>
          <FlexRow padding='24' vPadding='12'>
            <FlexCell grow={1} >
              <LabeledInput label='Purchase Description' { ...lens.prop('name').toProps() } >
                  <TextInput { ...lens.prop('name').toProps() } />
              </LabeledInput>
            </FlexCell>
          </FlexRow>
          <FlexRow padding='24' vPadding='12'>
            <FlexCell grow={1} >
              <LabeledInput label='Purchase Sum' { ...lens.prop('sum').toProps() } >
                  <NumericInput { ...lens.prop('sum').toProps() } formatOptions={{ maximumFractionDigits: 2, minimumFractionDigits: 2 }} />
              </LabeledInput>
            </FlexCell>
          </FlexRow>
          <FlexRow padding='24' vPadding='12'>
            <FlexCell grow={1} >
              <LabeledInput label='Purchase Quantity' { ...lens.prop('quantity').toProps() } >
                  <NumericInput { ...lens.prop('quantity').toProps() } formatOptions={{ maximumFractionDigits: 2, minimumFractionDigits: 2 }} />
              </LabeledInput>
            </FlexCell>
          </FlexRow>
          <FlexRow padding='24' vPadding='12'>
              <FlexSpacer />
              <Button color='green' caption='Confirm' onClick={ save } />
              <Button color='green' caption='Cancel' onClick={ () => svc.uuiRouter.redirect({ pathname: '/purchases' })} />
              <FlexSpacer />
          </FlexRow>
          <FlexSpacer />
        </ScrollBars>
      </Panel>
    </div>
  );
};
