import React, { useState } from 'react';
import { DataTable, ErrorNotification, FlexCell, FlexRow, LabeledInput, NumericInput, SuccessNotification, Text, TextInput } from '@epam/promo';
import { DataColumnProps, DataSourceState, Metadata, useArrayDataSource, useForm, useUuiContext } from '@epam/uui-core';
import { GraphQLCategory, GraphQLCommodity, GraphQLPurchase, GraphQLPurchaseItem, GraphQLSeller } from 'common';

import { extractDefaultUrlOnlyState, MasterDetail } from '../components/MasterDetail';
import { sellerQuery, sellersQuery } from '../queries';
import { Api } from '../services';
import { CategoryPicker } from '../components/CategoryPicker';
import { flatten } from 'ramda';
import { DateTime } from '../components/DateTime';
import { iconColumn } from '../components/iconColumn';

export const SellersPage = () => {
  return <MasterDetail<GraphQLSeller>
    masterQuery={ sellersQuery }
    detailQuery = { sellerQuery }
    pathname='sellers'
    resultQueryProp='sellers'
    columns={ columns }
    Detail={ SellerDetails }
    extractUrlOnlyState={extractDefaultUrlOnlyState}
  />;
};

const columns: DataColumnProps<GraphQLSeller>[] = [
  {
    key: 'id',
    caption: 'ID',
    render: seller => <Text color='gray60'>{ seller.id }</Text>,
    isSortable: true,
    isAlwaysVisible: true,
    width: 80,
  }, {
    key: 'name',
    caption: 'NAME',
    render: seller => <Text color='gray80' font='sans-semibold'>{ seller.name }</Text>,
    isSortable: true,
    grow: 1,
    width: 224,
  }, {
    key: 'inns',
    caption: 'INNS',
    render: seller => <Text>{ seller.inns.join(', ') }</Text>,
    width: 144,
  },
];

interface SellerDetailsProps {
  item: GraphQLSeller;
}

const SellerDetails = ({ item }: SellerDetailsProps) => {
  type UpdateSeller = Pick<GraphQLSeller, 'id' | 'defaultCategoryId' | 'name'>;
  type SellerPurchaseItem = Pick<GraphQLPurchaseItem, 'id'> & Pick<GraphQLPurchase, 'dateTime'> & Pick<GraphQLCommodity, 'name'>
    & Pick<GraphQLCategory, 'icon'>;

  const uui = useUuiContext<Api>();

  const [value, onValueChange] = useState<DataSourceState<any, number>>({});
  const items = flatten(item.purchases.map(purchase => purchase.items.map(item => ({
    id: item.id,
    dateTime: purchase.dateTime,
    name: item.commodity.name,
    icon: item.commodity.category.icon,
  }))));

  const dataSource = useArrayDataSource<SellerPurchaseItem, number, unknown>({
      items,
  }, [items]);

  const view = dataSource.useView(value, onValueChange, {});

  const columns: DataColumnProps<SellerPurchaseItem, number>[] = [
    iconColumn(item => item.icon), {
    key: 'id',
    caption: 'Id',
    render: item => <Text color='gray80'>{ item.id }</Text>,
    width: 80,
  }, {
    key: 'dateTime',
    caption: 'Date Time',
    render: item => <DateTime value={item.dateTime.toString()} />,
    width: 150,
  }, {
    key: 'name',
    caption: 'Name',
    render: item => <Text color='gray80'>{ item.name }</Text>,
    width: 300,
  }];

  const getMetadata = (state: UpdateSeller): Metadata<UpdateSeller> => ({
    props: {
      name: {
        isRequired: true,
      },
    },
  });

  const { lens, save } = useForm<UpdateSeller>({
    value: {
      id: item.id,
      defaultCategoryId: item.defaultCategoryId,
      name: item.name,
    },
    onSave: async value => {
      const result = await uui.api.updateSeller(value);

      if (typeof result === 'string') {
        uui.uuiErrors.reportError(new Error(`Error during seller saving: ${result}`));
      }

      return {
        form: value,
      };
    },

    onSuccess: async result => {
        uui.uuiNotifications.show(props => {
          return (
            <SuccessNotification { ...props }>
              <Text>Seller saved</Text>
            </SuccessNotification>
          );
        });
      },
    onError: error => uui.uuiNotifications.show(props => (
      <ErrorNotification { ...props }>
          <Text>Error on save</Text>
      </ErrorNotification>
    )),
    getMetadata,
    settingsKey: 'seller',
  });  

  return (
    <>
      <FlexRow padding='24' vPadding='12'>
        <FlexCell grow={1}>
          <LabeledInput label='ID'>
            <NumericInput
              value={ item.id ?? null }
              onValueChange={ () => {} }
              isReadonly
            />
          </LabeledInput>
        </FlexCell>
      </FlexRow>
      <FlexRow padding='24' vPadding='12'>
        <FlexCell grow={1}>
          <LabeledInput label='Name'>
              <TextInput
                { ...lens.prop('name').toProps() }
                onValueChange={ (value) => {
                  lens.prop('name').set(value!);
                  save();
                }}
              />
          </LabeledInput>
        </FlexCell>
      </FlexRow>
      <FlexRow padding='24' vPadding='12'>
        <FlexCell grow={1}>
          <LabeledInput label='INNs'>
              <TextInput
                value={ item.inns.join(', ') }
                onValueChange={ () => {} }
                isReadonly
              />
          </LabeledInput>
        </FlexCell>
      </FlexRow>
      <FlexRow padding='24' vPadding='12'>
        <FlexCell grow={1}>
          <LabeledInput label='Default Category'>
              <CategoryPicker
                { ...lens.prop('defaultCategoryId').toProps() }
                onValueChange={ (value) => {
                  lens.prop('defaultCategoryId').set(value);
                  save();
                }}
                searchPosition='body'
              />
          </LabeledInput>
        </FlexCell>
      </FlexRow>
      <FlexRow padding='24' vPadding='12'>
        <FlexCell grow={1}>
          <DataTable
            { ...view.getListProps() }
            getRows={ view.getVisibleRows }
            value={ value }
            onValueChange={ onValueChange }
            columns={ columns }
            headerTextCase='upper'
            />
          </FlexCell>
      </FlexRow>
    </>
  );
}
