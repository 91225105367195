export const categoriesQuery = `
query($range: Range, $sorting: [SortOption], $dateRange: DateRange, $filter: CategoriesFilter, $search: String) {
  categories(range: $range, sorting: $sorting, filter: $filter, search: $search) {
    id
    name
    icon
    sum(filter: $dateRange)
  }
}`;

export const categoriesPickerQuery = `
query($range: Range, $sorting: [SortOption], $filter: CategoriesFilter, $search: String) {
  categories(range: $range, sorting: $sorting, filter: $filter, search: $search) {
    id
    name
    icon
  }
}`;
