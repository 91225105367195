import React, { useCallback, useMemo } from 'react';
import { Button, DatePicker, Dropdown, DropdownContainer, ErrorNotification, FlexCell, FlexRow, LabeledInput, NumericInput, SearchInput, SuccessNotification, Text, TextInput, useForm } from '@epam/promo';
import { DataColumnProps, ILens, Metadata, useUuiContext } from '@epam/uui-core';
import { GraphQLPurchaseItem } from 'common';
import { iconColumn } from '../components/iconColumn';
import { DefaultUrlOnlyState, extractDefaultUrlOnlyState, MasterDetail } from '../components/MasterDetail';
import { useUrlState } from '../hooks/useUrlState';
import { AdaptiveItemProps, AdaptivePanel } from '@epam/uui-components';
import { Api } from '../services';
import { purchaseItemsQuery } from '../queries';
import { amountColumn } from '../components/amountColumn';
import { CategoryPicker } from '../components/CategoryPicker';
import { dateTimeColumn } from '../components/dateTimeColumn';

export const PurchaseItemsPage = () => {
  const [urlState, setUrlState] = useUrlState<DefaultUrlOnlyState>();

  const onSearchChange = useCallback((value: string) => {
    setUrlState({
      ...urlState,
      search: value,
    });
  }, [urlState, setUrlState]);

  const onCategoryChange = useCallback((value: number | undefined) => {
    setUrlState({
      ...urlState,
      filter: {
        categoryId: value,
      },
    });
  }, [urlState, setUrlState]);

  const onDateFromChange = useCallback((value: string) => {
    setUrlState({
      ...urlState,
      filter: {
        ...urlState.filter,
        dateFrom: value,
      },
    });
  }, [urlState, setUrlState]);

  const onDateToChange = useCallback((value: string) => {
    setUrlState({
      ...urlState,
      filter: {
        ...urlState.filter,
        dateTo: value,
      },
    });
  }, [urlState, setUrlState]);

  const filter = useMemo(() => ({
    categoryId: urlState.filter?.categoryId,
    dateFrom: urlState.filter?.dateFrom,
    dateTo: urlState.filter?.dateTo,
  }), [urlState]);

  const renderSearch = (item: AdaptiveItemProps) => {
      return (
        <FlexCell width={200}>
          <SearchInput value={urlState.dateRange} onValueChange={onSearchChange} placeholder='Search' />
        </FlexCell>
      )
  };

  const renderCategory = (item: AdaptiveItemProps) => {
    return (
      <FlexCell width={200}>
        <CategoryPicker value={ filter.categoryId } onValueChange={onCategoryChange} allowNulls placeholder='Category' />
      </FlexCell>
    )
  };

  const renderDateFrom = (item: AdaptiveItemProps) => {
    return (
      <FlexCell width={200}>
        <DatePicker value={filter.dateFrom} onValueChange={onDateFromChange} />
      </FlexCell>
    )
  };

  const renderDateTo = (item: AdaptiveItemProps) => {
    return (
      <FlexCell width={200}>
        <DatePicker value={filter.dateTo} onValueChange={onDateToChange} />
      </FlexCell>
    )
  };

  const items: AdaptiveItemProps<{data?: {caption: string}}>[] = [
    { id: '1', render: renderSearch, priority: 1 },
    { id: '2', render: renderCategory, priority: 1 },
    { id: '3', render: renderDateFrom, priority: 1 },
    { id: '4', render: renderDateTo, priority: 1 },
    { id: '5', render: (item, hiddenItems) => <Dropdown
          renderTarget={ (props) => <Button caption='Hidden Filters...' { ...props } /> }
          renderBody={ () => <DropdownContainer>{ hiddenItems?.map(item => {
            switch (item.id) {
              case '1':
                return (
                  <FlexRow padding='6' vPadding='12'>
                    {renderSearch(item)}
                  </FlexRow>
                );
              case '2':
                return (
                  <FlexRow padding='6' vPadding='12'>
                    {renderCategory(item)}
                  </FlexRow>
                );
              case '3':
                return (
                  <FlexRow padding='6' vPadding='12'>
                    {renderDateFrom(item)}
                  </FlexRow>
                );
              case '4':
                return (
                  <FlexRow padding='6' vPadding='12'>
                    {renderDateTo(item)}
                  </FlexRow>
                );
            }

            return undefined;
          }) }</DropdownContainer> }
      />,
      priority: 10, collapsedContainer: true,
    },
  ];  

  const search = useMemo(() => urlState.search, [urlState.search]);

  return <MasterDetail<GraphQLPurchaseItem, DefaultUrlOnlyState>
    masterQuery={ purchaseItemsQuery }
    detailQuery = { purchaseItemsQuery }
    pathname='spendings'
    resultQueryProp='purchaseItems'
    columns={ columns }
    Detail={ PurchaseItemDetails }
    extractUrlOnlyState={extractDefaultUrlOnlyState}
    filter={filter}
    search={search}
  >
    <AdaptivePanel items={items} />
  </MasterDetail>;
};

const columns: DataColumnProps<GraphQLPurchaseItem>[] = [
  iconColumn(purchaseItem => purchaseItem.calculatedCategory.icon),
  {
    key: 'id',
    caption: 'Id',
    render: purchaseItem => <Text color='gray80' font='sans-semibold'>{ purchaseItem.id }</Text>,
    isSortable: true,
    width: 60,
  },
  dateTimeColumn<GraphQLPurchaseItem>({ key: 'dateTime', caption: 'Date Time', dateTimeGetter: purchaseItem => purchaseItem.parent.dateTime, isSortable: true }),
  {
    key: 'name',
    caption: 'NAME',
    render: purchaseItem => <Text color='gray80' font='sans-semibold'>{ purchaseItem.commodity.name }</Text>,
    isSortable: true,
    grow: 1,
    width: 224,
  },
  amountColumn<GraphQLPurchaseItem>({ key: 'quantity', caption: 'Quantity', amountGetter: purchaseItem => purchaseItem.quantity, isSortable: true }),
  amountColumn<GraphQLPurchaseItem>({ key: 'price', caption: 'Price', amountGetter: purchaseItem => purchaseItem.price, isSortable: true }),
  amountColumn<GraphQLPurchaseItem>({ key: 'sum', caption: 'Sum', amountGetter: purchaseItem => purchaseItem.sum, isSortable: true }),
];

interface PurchaseItemDetailsProps {
  item: GraphQLPurchaseItem;
}

const PurchaseItemDetails = ({ item }: PurchaseItemDetailsProps) => {
  const uui = useUuiContext<Api>();

  type UpdatePurchaseItem = Pick<GraphQLPurchaseItem, 'id' | 'categoryId' | 'commodity'>;

  const getMetadata = (state: UpdatePurchaseItem): Metadata<UpdatePurchaseItem> => ({
    props: {
      categoryId: { isRequired: true, },
    },
  });

  const { lens, save } = useForm<UpdatePurchaseItem>({
    value: {
      id: item.id,
      categoryId: item.categoryId,
      commodity: item.commodity,
    },
    onSave: async value => {
      const { commodity, ...rest } = value;
      const result = await uui.api.updatePurchaseItem(rest);

      if (typeof result === 'string') {
        uui.uuiErrors.reportError(new Error(`Error during purchase item saving: ${result}`));
      }

      return {
        form: value,
      };
    },

    onSuccess: async result => {
        uui.uuiNotifications.show(props => {
          return (
            <SuccessNotification { ...props }>
              <Text>Commodity saved</Text>
            </SuccessNotification>
          );
        });
      },
    onError: error => uui.uuiNotifications.show(props => (
      <ErrorNotification { ...props }>
          <Text>Error on save</Text>
      </ErrorNotification>
    )),
    getMetadata,
    settingsKey: 'commodity',
  });  

  return (
      <>
        <FlexRow padding='24' vPadding='12'>
          <FlexCell grow={1}>
            <LabeledInput label='ID'>
              <NumericInput
                value={ item.id }
                onValueChange={ () => {} }
                isReadonly
              />
            </LabeledInput>
          </FlexCell>
        </FlexRow>
        <FlexRow padding='24' vPadding='12'>
          <FlexCell grow={1}>
            <LabeledInput label='Name'>
              <TextInput
                value={ item.commodity.name }
                onValueChange={ () => {} }
                isReadonly
              />
            </LabeledInput>
          </FlexCell>
        </FlexRow>
        <FlexRow padding='24' vPadding='12'>
          <FlexCell grow={1}>
            <LabeledInput label='Default Category'>
              <CategoryPicker
                isReadonly
                allowNulls
                value={ lens.prop('commodity').get().categoryId }
                onValueChange={ () => {} }
                searchPosition='body'
              />
            </LabeledInput>
          </FlexCell>
        </FlexRow>
        <FlexRow padding='24' vPadding='12'>
          <FlexCell grow={1}>
            <LabeledInput label='Category'>
              <CategoryPicker
                allowNulls
                { ...lens.prop('categoryId').toProps() }
                onValueChange={ (value) => {
                  (lens.prop('categoryId') as ILens<number | undefined>).set(value);
                  save();
                }}
                searchPosition='body'
              />
            </LabeledInput>
          </FlexCell>
        </FlexRow>
    </>
  );
}
