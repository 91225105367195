import React from 'react';
import { DataColumnProps } from '@epam/uui-core';
import { DataTableCell, NumericInput } from '@epam/promo';

type AmountColumnOptions<T> = {
  key: string,
  caption: string,
  amountGetter: (item: T) => number | null,
  isSortable?: boolean,
};

export const amountColumn = <T extends unknown>(options: AmountColumnOptions<T>): DataColumnProps<T> => ({
  key: options.key,
  caption: options.caption,
  isSortable: options.isSortable,
  render: item => <NumericInput
    value={options.amountGetter(item)}
    onValueChange={() => {}}
    isDisabled
    align='right'
    formatOptions={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }}
  />,
  width: 150,
  renderCell: (props) => <DataTableCell
      { ...props }
      padding='12'
      onValueChange={undefined}
    />,
});
