export const purchasesQuery = `
query purchases($filter:PurchasesFilter, $range: Range, $sorting: [SortOption]) {
  purchases(filter: $filter, range: $range, sorting: $sorting) {
    id
    dateTime
    sum
    isManual
    seller {
      id
      name
    }
  } 
}
`;

export const purchaseQuery = `
query purchases($filter:PurchasesFilter) {
  purchases(filter: $filter) {
    id
    dateTime
    sum
    isManual
    seller {
      id
      name
    }
    attachments {
      id
      uploadId
      upload {
        id
        size
        originalName
        googleFileId
      }
    }
    items {
      id
      price
      sum
      quantity
      isAdminOnly
      commodity {
        id
        name
        codes
        categoryId
        category {
          id
          icon
        }
      }
      categoryId
      category {
        id
        icon
      }
    }
    slip {
			id
      slipJsonId
      dateTime
      cashTotalSum
      ecashTotalSum
      fiscalDocumentNumber
      fiscalDriveNumber
      fiscalSign
      kktRegId 
			nds0
      operationType
      operator
      receiptCode
      requestNumber
      shiftNumber
      taxationType
      totalSum
      user
      userInn
      buyerPhoneOrAddress
      internetSign
      machineNumber
      ndsNo
      sellerAddress
      code
      creditSum
      fiscalDocumentFormatVer
      prepaidSum
      provisionSum
      retailPlace
      appliedTaxationType
      nds18
      operatorInn
      retailPlaceAddress
      fnsUrl
      nds18118
      propertiesData
      paymentAgentType
      nds10
      messageFiscalSign
      region
      numberKkt
      redefine_mask
      nds10110
      checkingLabeledProdResult
      buyer
      items {
        id
        slipId
        name
        nds0
        price
        quantity
        sum
        ndsNo
        nds
        paymentType
        productType
        ndsSum
        paymentAgentByProductType
        providerInn
        unit
        itemsQuantityMeasure
        checkingProdInformationResult
        labelCodeProcesMode
        propertiesItem
        productCodeDataError
        unitNds
        ndsRate
        calculationTypeSign
        calculationSubjectSign
        industryReceiptDetails {
          id
          idFoiv
          foundationDocDateTime
          foundationDocNumber
          industryPropValue
        }
        productCodes {
          id
          rawProductCode
          productIdType
          gtin
          sernum
          sourceField
        }
        providerData {
          id
          providerName
          providerPhone
        }
      }
      properties {
        id
        propertyName
        propertyValue
      }
      metadata {
        pkId
        id
        ofdId
        subtype
        address
        receiveDate
      }
      industryReceiptDetails {
        id
        idFoiv
        foundationDocDateTime
        foundationDocNumber
        industryPropValue
      }
    }
  } 
}
`;

export const newPurchaseMutation = `mutation ($item: InputNewPurchase) {
  purchaseCreate(item: $item)
}`;

export const updatePurchaseMutation = `mutation ($item: InputPurchase) {
  purchaseUpdate(item: $item)
}`;

export const deletePurchaseMutation = `mutation ($id: Int) {
  deletePurchase(id: $id)
}`;
